.logo_heading{
    margin-bottom:40px;
    font-size: 35px;
}

.logo_para{
    font-size:20px;
}

@media screen and (max-width:1305px) {
    .header_signin{
        flex-direction: column;
    }
}

@media screen and (max-width:1538px) {
    .header_signup{
        flex-direction: column;
    }
}

@media screen and (max-width:1050px) {
    .logo_heading{
        font-size: 27px;
    }
    .logo_para{
        font-size:20px;
    }
}

@media screen and (max-width:900px) {
    .logo_heading{
        font-size: 19px;
    }
    .logo_para{
        font-size:15px;
    }
}

@media screen and (max-width:920px) {
    .transparent_container{
        display: none !important;
    }

    .container{
        grid-template-columns: 0% 100%;
        background-image: url(/src/image/logo/loginPageLogo.jpg);
        background-repeat: no-repeat;
        padding: 20px 0;
    }
}

@media screen and (max-width:500px) {
    .form_container{
        width: 90%;
    }
}

@media screen and (max-width:640px) {
    .form_container_email_verification{
        width: 90%;
    }
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar{
    width: 6px; /* Set the width of the scrollbar */
    height: 6px; /* Set the height of the scrollbar for horizontal scrolling */
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
    background: #888; /* Set the color of the scrollbar thumb */
    border-radius: 10px; /* Add rounded corners */
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
    background: #555; /* Change the color on hover */
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
    background: #f1f1f1; /* Set the color of the track */
    border-radius: 10px; /* Match the radius with the thumb */
}

/* Tooltip hover effect */
button:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }